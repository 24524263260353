<template>
  <ion-page>
    <ion-header mode="md" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons>
          <ion-button @click="$router.back()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 12H5" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 19L5 12L12 5" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div id="container">
        <div class="form">
          <div class="title mb-2">
            Buat Akun Baru
          </div>

          <label for="name">Name</label>
          <div class="input-group">
            <ion-input type="text" class="input" placeholder="name" v-model="name"></ion-input>
          </div>

          <label for="password">Password</label>
          <div class="input-group">
            <ion-input :type="see? 'text': 'password'" class="input" placeholder="password" v-model="password" @keyup.enter="register"></ion-input>
            <span class="after" @click="see=!see">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12C23 12 19 20 12 20C5 20 1 12 1 12Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
          </div>
          <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="register">Buat Akun</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonHeader, IonToolbar,  IonButtons, IonButton, IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonInput
  },
  data () {
    return {
      name: null,
      password: null,
      see: false
    }
  },
  methods: {
    async register () {
      let email = this.$route.query.email
      if(email === undefined || email === '')
        return;

      if(this.password.length < 6 )
        return;

      let referralBy = localStorage.getItem("referral_by") || null

      await this.$store.dispatch('auth/register', {
        name: this.name,
        email: email,
        password: this.password,
        referral_by: referralBy
      })

      this.$router.push('/')
    }
  }
})
</script>

<style lang="scss">
#container {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 25%;
  transform: translateY(-20%);
  padding: 1rem;
}
.title {
  font-size: 24px;
  // margin-bottom: 2rem;
}
label {
  text-align: left;
}
</style>
